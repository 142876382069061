import axios from 'axios'
import {Toast} from 'vant'
import router from "../router";
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
// const baseURL = 'http://passage-car-test.rlinking.com/'
// const baseURL = 'http://192.168.0.194:10700/'

var baseURL = "";
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: baseURL,
    // 超时
    timeout: 10000
})

// request拦截器 request interceptor
service.interceptors.request.use(config => {
    if (sessionStorage.getItem('token')) {
        config.headers['token'] = sessionStorage.getItem('token') // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
}, error => {
    return Promise.reject(error)
})
// respone拦截器
service.interceptors.response.use(
    response => {
        if (response.config.responseType == "blob") {
            return response;
        } else if (response.data.code === 'NO_LOGIN') {
            sessionStorage.removeItem('token')
        } else if (response.data.code != 'SUCCESS' && response.data.code != 'NEED_PAY') {
            if (response.data.message != null) {
                Toast({
                    message: response.data.message,
                    type: 'fail',
                    duration: 2000
                });
            }
            Promise.reject(`ERROR:${response.data.code}`)
        } else {
            return response;
        }
    },
)

export default service
