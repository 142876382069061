import request from '@/directives/request'
import config from '@/config'

//公众号login
export function login(data) {
    return request({
        url: `${config.api}/endUser/auth`,
        method: 'get',
        params: {code: data},
    })
}

//支付宝login
export function loginZfb(data) {
    return request({
        url: `${config.api}/endUser/auth/zfb`,
        method: 'get',
        params: {code: data},
    })
}
