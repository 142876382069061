let config = {};
if (process.env.NODE_ENV === "development") {
    //生产环境
    config = {
        api: 'https://platform-user-test.rlinking.com',
        bpi: 'https://passage-car-test.rlinking.com',
        cpi: 'http://passage-person-test.rlinking.com'
    }
} else if (process.env.NODE_ENV === "production") {
    //正式环境
    config = {
        api: 'https://platform-user.rlinking.com',
        bpi: 'https://passage-car.rlinking.com',
        cpi: 'https://passage-person.rlinking.com'
    }
} else if (process.env.NODE_ENV === "test") {
    //测试环境
    config = {
        api: 'https://platform-user-test.rlinking.com',
        bpi: 'https://passage-car-test.rlinking.com',
        cpi: 'http://passage-person-test.rlinking.com'
    }
}
export default config
