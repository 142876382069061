import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-weapp']

//引入lib-flexible 用于设置rem基准值
import "lib-flexible/flexible"
import 'vant/lib/index.css';

// alert(process.env.NODE_ENV)
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
